import embraceAi from "../../assets/img/embraceAi.jpeg";


export const LatestNewsData = [
    {
        img: "https://i.ytimg.com/vi/Lz6Vq4snOUg/hqdefault.jpg", 
        date: "July 07, 2024", 
        description: "Founder and CEO of Rhombus Power, Dr. Anshu Roy, on CNN's First Move with Julia Chatterley", 
        category: "News", 
        link:"https://www.youtube.com/watch?v=Lz6Vq4snOUg", 
        external:true,
        row:true
    },
    {
        img: "https://dims.apnews.com/dims4/default/278a2cf/2147483647/strip/true/crop/1920x1278+0+0/resize/1440x959!/format/webp/quality/90/?url=https%3A%2F%2Fassets.apnews.com%2F93%2F7d%2Fa2184f9212e25e4eb8dc5049bbb8%2F8d3bcb0522364ff6a25db6d5f4e6cc2a", 
        date: "May 24, 2024", 
        description: "US intelligence agencies' embrace of generative AI is at once wary and urgent", 
        category: "News", 
        link:"https://apnews.com/article/us-intelligence-services-ai-models-9471e8c5703306eb29f6c971b6923187", 
        external:true,
        row: true
    },
    {
        img: embraceAi, 
        date: "May 23, 2024", 
        description: "Intelligence Embracing AI", 
        category: "News", 
        link:"https://apnews.com/article/us-intelligence-gen-ai-takeaways-8ae7d4768c8e64429061c8ba3b272d19", 
        external:true,
        row: true
    }
]